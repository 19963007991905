import React from 'react';
import { Users, MessageSquare } from 'react-feather';
import styled from 'styled-components';
import Subtitle from 'components/blog/subtitle';
import Body from 'components/common/body';
import CompaniesCloud from 'components/common/companiesCloud';
import Heading from 'components/common/heading';
import Link from 'components/common/link';
import Roadmap from 'components/common/roadmap';
import { RoadmapItem } from 'components/common/roadmap/types';
import LineBreak from 'components/common/sectionBreak/lineBreak';
import { SocialMediaIcons } from 'components/common/socialMedia';
import Container from 'components/container';
import PublicLayout from 'components/layouts/Public';
import Logo from 'components/logo';
import MainImageDiv from 'components/pages/homePage/mainImage';
import { COMPANIES_LINE_ITEMS } from 'constants/companies';
import { IS_PRODUCTION } from 'constants/env';
import ContactMe from 'containers/contactMe';
import { mq } from 'utils/responsive';
import { theme } from 'utils/theme';
import { CLIENT_TESTIMONIALS } from './resume';

// TODO: make the Subtitle/Title responsive instead, so we have the logic in one plance
// This logic also exists on the metadata of a post.
const ResponsiveDetailsBox = styled.div`
  padding-left: 40px;

  ${mq.ltsm`
    padding-left: 10px;
  `}
`;

export const ROADMAP_CLIENT_TESTIMONIALS: RoadmapItem[] = [
  {
    title: 'What are my clients saying?',
    subtitle: '',
    content: [],
    dateText: '',
    contentStyle: { background: 'white', color: '#111827' },
    iconStyle: { background: theme.customColors.primary, color: 'white' },
    icon: <Users />,
  },
  {
    title: '',
    subtitle: ``,
    content: [
      "I had the feeling that you could get things done quickly. [...] I trusted you to give a good result, and that's what happened. [...] I could not have wished for anything better.",
      'Josua Vieten, CEO ExoMatter',
    ],
    dateText: '',
    contentStyle: { background: 'white', color: '#111827' },
    iconStyle: { background: theme.customColors.success, color: 'white' },
    icon: <MessageSquare />,
  },
  ...CLIENT_TESTIMONIALS.map((testimonial) => ({
    title: '',
    subtitle: '',
    content: [testimonial],
    dateText: '',
    contentStyle: { background: 'white', color: '#111827' },
    iconStyle: { background: theme.customColors.success, color: 'white' },
    icon: <MessageSquare />,
  })),
];

const HomePage = () => {
  return (
    <PublicLayout>
      <MainImageDiv>
        <Container centerContent>
          <Logo boxShadowColor={theme.colors.gray300} height={250} width={250} />
          <Heading centered level={100} margin="40px 0" alternates>
            Mihai Anton
          </Heading>
          <Heading centered level={300}>
            Senior Software Engineer | AI consultant
          </Heading>
          <SocialMediaIcons baseHeight={32} margin="40px 0 0 0" color={theme.colors.black} />
        </Container>
      </MainImageDiv>
      <Container>
        <ResponsiveDetailsBox>
          <Subtitle margin="15px 0 15px 0px">
            Founder @aiflow.ltd | Cloud & AI software solutions
          </Subtitle>
          <Subtitle margin="15px 0 15px 0px">
            Senior Machine Learning Engineer @metaphysic.ai
          </Subtitle>
          <Subtitle margin="15px 0 15px 0px">🌍 Cluj-Napoca, Romania</Subtitle>
        </ResponsiveDetailsBox>
        <Body>
          Hi there! I&apos;m Mihai! I&apos;ve been in the software industry for more than 7 years,
          splitting between Software Engineering and AI. After spending some years at Google and
          Bloomberg solving problems at scale, and building products for startups, I decided to
          build{' '}
          <Link
            key="link"
            target="_blank"
            rel="noopener noreferrer"
            color={theme.customColors.linkBlue}
            href="https://www.aiflow.ltd/"
          >
            AI Flow
          </Link>
          , an AI focused Software Agency. We work with small and medium companies, building deep
          tech AI products from 0, extending existing projects, and taking 100% ownership of the
          technical part, so you can focus on your business. Having a deep understanding in AI and
          Machine Learning, I also do consulting for high growth companies. Let&apos;s get in touch!
        </Body>
        {/* <Body>
          Hi there! I&apos;m Mihai! I&apos;ve been in the software industry for more than 7 years,
          collaborating with small and medium companies, helping them build modern and scalable
          software. In the past, I worked at Bloomberg and Google, did research on AutoML and built
          a SaaS AI Startup, failing the product-market fit. Moving forward, I aim to collaborate
          with startups and medium companies, while being active in the startup environment.
          Let&apos;s get in touch!
        </Body> */}

        {!IS_PRODUCTION && (
          <>
            <LineBreak />
            <Heading level={100}>Heading level 100</Heading>
            <Heading level={200}>Heading level 200</Heading>
            <Heading level={300}>Heading level 300</Heading>
            <Heading level={400}>Heading level 400</Heading>
            <Body>Body, normal</Body>
            <Body size="small">Body, small</Body>
            <Body size="xsmall">Body, xsmall</Body>
          </>
        )}
      </Container>

      <Container margin="50px 0 0 0">
        <Heading level={200}>Some of the companies I worked with:</Heading>
        <CompaniesCloud items={COMPANIES_LINE_ITEMS} />
      </Container>

      <Container
        backgroundColor={theme.customColors.lightGraySemiTransparent}
        centerContent
        py="20px"
        pylg="20px"
      >
        <Roadmap events={ROADMAP_CLIENT_TESTIMONIALS} />
      </Container>
      <Container>
        <ContactMe />
      </Container>
    </PublicLayout>
  );
};

export default HomePage;
