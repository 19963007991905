import Image from 'next/image';
import React from 'react';
import styled from 'styled-components';
import { CompaniesLineItem } from '../companiesLine/types';

// Styled component for the container of the word cloud
const CloudContainer = styled.div`
  position: relative;
  width: 100%; // Use the full width of the parent container
  height: 600px; // Set a fixed height or make it responsive as needed
  margin: auto;
  overflow: hidden; // Prevents overflow of child elements
`;

interface CloudItemProps {
  scale?: number;
  centered?: boolean;
  left?: number;
  top?: number;
}

const CloudItem = styled.div<CloudItemProps>`
  position: absolute;
  transform: ${(props) => `scale(${props.scale || 1.0})`}; // Random rotation
  transition: transform 0.3s ease; // Smooth transition for transform changes

  &:hover {
    ${(props) => `transform: scale(${(props.scale || 1) * 1.2});`}// Slight zoom on hover, optional
  }

  left: ${(props) => `${(props.left || 0.5) * 100}%`}; // Random horizontal position
  top: ${(props) => `${(props.top || 0.5) * 100}%`}; // Random vertical position
`;

interface CompaniesCloudProps {
  items: CompaniesLineItem[];
}

const CompaniesCloud = ({ items }: CompaniesCloudProps) => {
  return (
    <CloudContainer>
      {items.map(({ width, height, name, scale, image, left, top }, idx) => (
        <CloudItem key={idx} scale={scale} left={left} top={top}>
          <Image width={width} height={height} key={`${idx}-${name}`} alt={name} src={image} />
        </CloudItem>
      ))}
    </CloudContainer>
  );
};

export default CompaniesCloud;
